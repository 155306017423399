import { CouponGoodsLimit, CouponObtainWay, CouponShopLimit, CouponType, CouponUseTimeType } from '@/common/enum';
import { DownLoadReform, Get, Post } from './Request';
// 新增推广商家参数
export class ExtensionArs {
}
// 推广商家列表参数
export class ExtensionListResponse {
}
// 创建优惠券参数
export class CreateCouponArgs {
    constructor() {
        this.couponAmount = '';
        this.couponType = CouponType.Quota;
        this.couponNum = 1;
        this.couponGeneratedCode = '';
        this.promoteNo = '';
        this.couponNum = 1;
        this.holdNum = 1;
        this.name = '';
        this.obtainNum = 1;
        this.holdNum = 1;
        this.obtainTimeEnd = '';
        this.obtainTimeStart = '';
        this.obtainWay = CouponObtainWay.Link;
        this.productRestrictDetail = ''; // 商品限制详情
        this.productRestrictNum = 1;
        this.productRestrictType = CouponGoodsLimit.Goods;
        this.storeRestrictType = CouponShopLimit.Shop;
        this.thresholdMoney = 0;
        this.useDeadline = '';
        this.useTimeRestrictType = CouponUseTimeType.Custom;
        this.useTimeStart = '';
        this.useValidityPeriod = '';
    }
}
// 生成批次数据列表参数
export class BranchListResponse {
}
// 生成优惠券列表参数
export class CouponListResponse {
}
// 优惠券补充列表返回数据
export class CouponSupplymentResponse {
}
// 新增推广商家
export function CreateExtension(data) {
    return Post(`/storeProductSelection/addPromotionMerchants`, data);
}
// 推广商家列表
export function GetExtensionList(data) {
    return Get(`/storeProductSelection/getPromotionMerchantsList?merchantStoreId=${data.merchantStoreId}&promotionMerchantsName=${data.promotionMerchantsName || ''}&pageNum=${data.pageNum || 1}&pageSize=${data.pageSize || 20}`);
}
// 获取优惠券生成码
export function GetCouponGeneratedCode(data) {
    return Get(`/coupon/generatedCode`, data);
}
// 创建优惠券
export function CreateCoupon(data) {
    return Post("/coupon/create", data);
}
// 查看批次数据列表
export function GetBatchList(data) {
    return Get(`/coupon/batch/list?merchantId=${data.merchantId}&promoteNo=${data.promoteNo}&batchNo=${data.batchNo}&enableStatus=${data.enableStatus}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`);
}
// 限制商品
export function GetLimitRules(data) {
    return Get(`/coupon/productRestrictList?ruleId=${data.ruleId}`);
}
// 变更优惠券状态
export function ChangeCouponStatus(data) {
    return Post("/coupon/changeStatus", data);
}
// 优惠券列表
export function GetCouponList(data) {
    return Post(`/coupon/list?batchNo`, data);
}
// 置失效
export function SetCouponInvalid(data) {
    return Post(`/coupon/invalid`, data);
}
// 可用优惠券列表
export function GetUsableCouponList(data) {
    return Get(`/coupon/availableCouponBatchByProduct?StoreProductSelectionsId=${data.StoreProductSelectionsId}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`);
}
// 优惠券延期
export function ChangeCouponTime(data) {
    return Post(`/coupon/postpone`, data);
}
// 优惠券列表 - 下载校验
export function VerityCouponList(data) {
    return Post("/coupon/verifyCouponDetailExport", data, true);
}
// 优惠券列表 - 下载 
export function DownloadCouponList(data, name) {
    return DownLoadReform(`/coupon/export?token=${data.token}`, name + '.xlsx');
}
// 追加优惠券
export function SupplymentCoupon(data) {
    return Post("/coupon/addCouponNum", data, true);
}
// 查询优惠券追加记录
// 限制商品
export function CouponSupplymentList(data) {
    return Get(`/coupon/batchAddRecord/list?batchNo=${data.batchNo}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`);
}
// 查看优惠券批次详情 
export function CouponDetail(data) {
    return Get(`/coupon/batch/detail?batchNo=${data.batchNo}`);
}
